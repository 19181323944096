<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            label="년도"
            name="measureYear"
            type="year"
            default="today"
            v-model="searchParam.measureYear"
          />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="배출량 목록"
          tableId="processManual"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="true"
          :usePaging="false"
          :expandAll="true"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'daily-air-report-manage',
  data() {
    return {
      col1Items: [
        { code: '1', codeName: '고정연소' },
        { code: '2', codeName: '이동연소' },
      ],
      col2Items: [
        { code: '1', codeName: '공정시설' },
        { code: '2', codeName: '일반보일러실' },
        { code: '3', codeName: '기타' },
      ],
      grid: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '사업장',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
            fix: true,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '부서',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
            fix: true,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '공정',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
            fix: true,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '배출유형',
            align: 'center',
            style: 'width: 100px',
            sortable: true,
            fix: true,
          },
          {
            name: 'col6',
            field: 'col6',
            label: '배출시설',
            align: 'left',
            sortable: true,
            style: 'width: 200px',
            type: 'link',
            fix: true,
          },
          {
            name: 'col0',
            field: 'col0',
            label: '활동데이터',
            align: 'center',
            child: [
              {
                name: 'col7',
                field: 'col7',
                label: '활동중분류',
                align: 'center',
                sortable: true,
                style: 'width: 100px',
              },
              {
                name: 'col8',
                field: 'col8',
                label: '활동자료명',
                align: 'center',
                sortable: true,
                style: 'width: 100px',
              },
              {
                name: 'col9',
                field: 'col9',
                label: '단위',
                align: 'center',
                sortable: true,
                style: 'width: 60px',
              },
              {
                name: 'col10',
                field: 'col10',
                label: '활동량',
                align: 'center',
                sortable: true,
                style: 'width: 100px',
              },
            ],
          },
          {
            name: 'col20',
            field: 'col20',
            label: '매개변수',
            align: 'center',
            child: [
              {
                name: 'col21',
                field: 'col21',
                label: '적용Tier',
                align: 'center',
                sortable: true,
                style: 'width: 100px',
              },
              {
                name: 'col22',
                field: 'col22',
                label: '연간사용량',
                align: 'center',
                sortable: true,
                style: 'width: 100px',
              },
              {
                name: 'col23',
                field: 'col23',
                label: 'TJ',
                type: 'cost',
                sortable: true,
                style: 'width: 100px',
              },
              {
                name: 'col24',
                field: 'col24',
                label: 'TOE',
                type: 'cost',
                sortable: true,
                style: 'width: 100px',
              },
            ]
          },
          {
            name: 'col25',
            field: 'col25',
            label: '배출계수',
            child: [
              {
                name: 'col26',
                field: 'col26',
                label: 'TCO2/TJ,MWH',
                type: 'cost',
                sortable: true,
                style: 'width: 120px',
              },
              {
                name: 'col27',
                field: 'col27',
                label: 'kgCH4/TJ,MWH',
                type: 'cost',
                sortable: true,
                style: 'width: 120px',
              },
              {
                name: 'col28',
                field: 'col28',
                label: 'kgN20/TJ,MWH',
                type: 'cost',
                sortable: true,
                style: 'width: 120px',
              },
              {
                name: 'col29',
                field: 'col29',
                label: 'tCO2eq/TK,MWH',
                type: 'cost',
                sortable: true,
                style: 'width: 120px',
              },
            ],
          },
          {
            name: 'col30',
            field: 'col30',
            label: '순발열량(GJ/단위)',
            type: 'cost',
            sortable: true,
            style: 'width: 120px',
          },
          {
            name: 'col31',
            field: 'col31',
            label: '산화율',
            type: 'cost',
            sortable: true,
            style: 'width: 120px',
          },
          {
            name: 'col32',
            field: 'col32',
            label: 'GWP',
            child: [
              {
                name: 'col33',
                field: 'col33',
                label: 'CO2',
                type: 'cost',
                sortable: true,
                style: 'width: 100px',
              },
              {
                name: 'col34',
                field: 'col34',
                label: 'CH4',
                type: 'cost',
                sortable: true,
                style: 'width: 100px',
              },
              {
                name: 'col35',
                field: 'col35',
                label: 'N20',
                type: 'cost',
                sortable: true,
                style: 'width: 100px',
              },
            ],
          },
          {
            name: 'col40',
            field: 'col40',
            label: '배출량',
            child: [
              {
                name: 'col41',
                field: 'col41',
                label: '1Q',
                child: [
                  {
                    name: 'col142',
                    field: 'col142',
                    label: '1월',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 100px',
                  },
                  {
                    name: 'col143',
                    field: 'col43',
                    label: '2월',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 100px',
                  },
                  {
                    name: 'col144',
                    field: 'col44',
                    label: '3월',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 100px',
                  },
                  {
                    name: 'col145',
                    field: 'col45',
                    label: '소계',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 100px',
                  },
                ]
              },
              {
                name: 'col141',
                field: 'col141',
                label: '2Q',
                child: [
                  {
                    name: 'col242',
                    field: 'col242',
                    label: '4월',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 100px',
                  },
                  {
                    name: 'col243',
                    field: 'col243',
                    label: '5월',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 100px',
                  },
                  {
                    name: 'col244',
                    field: 'col244',
                    label: '6월',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 100px',
                  },
                  {
                    name: 'col245',
                    field: 'col245',
                    label: '소계',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 100px',
                  },
                ]
              },
              {
                name: 'col341',
                field: 'col341',
                label: '3Q',
                child: [
                  {
                    name: 'col342',
                    field: 'col342',
                    label: '7월',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 100px',
                  },
                  {
                    name: 'col343',
                    field: 'col343',
                    label: '8월',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 100px',
                  },
                  {
                    name: 'col344',
                    field: 'col344',
                    label: '9월',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 100px',
                  },
                  {
                    name: 'col345',
                    field: 'col345',
                    label: '소계',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 100px',
                  },
                ]
              },
              {
                name: 'col441',
                field: 'col441',
                label: '4Q',
                child: [
                  {
                    name: 'col442',
                    field: 'col442',
                    label: '10월',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 100px',
                  },
                  {
                    name: 'col443',
                    field: 'col443',
                    label: '11월',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 100px',
                  },
                  {
                    name: 'col444',
                    field: 'col444',
                    label: '12월',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 100px',
                  },
                  {
                    name: 'col445',
                    field: 'col445',
                    label: '소계',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 100px',
                  },
                ]
              },
              {
                name: 'col555',
                field: 'col555',
                label: '합계',
                type: 'cost',
                sortable: true,
                style: 'width: 100px',
              }
            ],
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        col1: null,
        col2: null,
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '80%',
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          col1: '사업장1', 
          col2: '설비팀', 
          col3: '건조공정', 
          col4: '직접배출', 
          col6: '공정연소시설 #021', 
          col7: 'LNG', 
          col8: '보조연료', 
          col9: 'km3', 
          col10: '2.123', 
          col21: '1', 
          col22: '231.12', 
          col23: '2.122', 
          col24: '1.015', 
          col26: '2.1', 
          col27: '2.5', 
          col28: '3.1', 
          col29: '3.4', 
          col30: '21', 
          col31: '35', 
          col33: '12.12', 
          col34: '0.231', 
          col35: '0.51', 
          col142: '5', 
          col143: '2', 
          col144: '1', 
          col145: '14.212', 
          col242: '7', 
          col243: '1', 
          col244: '4', 
          col245: '11.57', 
          col342: '1', 
          col343: '3', 
          col344: '8', 
          col345: '5.252', 
          col442: '3', 
          col443: '2', 
          col444: '9', 
          col445: '7.252', 
          col555: '40.134', 
        },
        {
          col1: '사업장1', 
          col2: '설비팀', 
          col3: '건조공정', 
          col4: '직접배출', 
          col6: '공정연소시설 #045', 
          col7: 'LNG', 
          col8: '보조연료', 
          col9: 'km3', 
          col10: '3.23', 
          col21: '2', 
          col22: '154.4', 
          col23: '3.216', 
          col24: '1.02', 
          col26: '5.1', 
          col27: '2.5', 
          col28: '3.1', 
          col29: '3.4', 
          col30: '21', 
          col31: '35', 
          col33: '12.12', 
          col34: '0.231', 
          col35: '0.51', 
          col142: '5', 
          col143: '2', 
          col144: '1', 
          col145: '14.212', 
          col242: '3', 
          col243: '2', 
          col244: '6', 
          col245: '15.21', 
          col342: '2', 
          col343: '3', 
          col344: '4', 
          col345: '6.124', 
          col442: '7', 
          col443: '3', 
          col444: '5', 
          col445: '2.252', 
          col555: '24.134', 
        },
        {
          col1: '사업장1', 
          col2: '설비팀', 
          col3: '건조공정', 
          col4: '직접배출', 
          col6: '공정연소시설 #015', 
          col7: 'LNG', 
          col8: '보조연료', 
          col9: 'km3', 
          col10: '5.91', 
          col21: '1', 
          col22: '145.12', 
          col23: '3.12', 
          col24: '2.03', 
          col26: '3.1', 
          col27: '2.5', 
          col28: '3.1', 
          col29: '3.4', 
          col30: '21', 
          col31: '35', 
          col33: '12.12', 
          col34: '0.231', 
          col35: '0.51', 
          col142: '5', 
          col143: '2', 
          col144: '1', 
          col145: '14.212', 
          col242: '7', 
          col243: '1', 
          col244: '4', 
          col245: '11.57', 
          col342: '1', 
          col343: '3', 
          col344: '8', 
          col345: '5.252', 
          col442: '3', 
          col443: '2', 
          col444: '9', 
          col445: '7.252', 
          col555: '40.134', 
        },
        {
          col1: '사업장1', 
          col2: '설비팀', 
          col3: '건조공정', 
          col4: '직접배출', 
          col6: '공정연소시설 #067', 
          col7: 'LNG', 
          col8: '보조연료', 
          col9: 'km3', 
          col10: '6.8', 
          col21: '1', 
          col22: '141.3', 
          col23: '4.124', 
          col24: '2.055', 
          col26: '3.1', 
          col27: '2.5', 
          col28: '3.1', 
          col29: '3.4', 
          col30: '21', 
          col31: '35', 
          col33: '12.12', 
          col34: '0.231', 
          col35: '0.51', 
          col142: '5', 
          col143: '2', 
          col144: '1', 
          col145: '14.212', 
          col242: '7', 
          col243: '1', 
          col244: '4', 
          col245: '11.57', 
          col342: '1', 
          col343: '3', 
          col344: '8', 
          col345: '5.252', 
          col442: '3', 
          col443: '2', 
          col444: '9', 
          col445: '7.252', 
          col555: '40.134', 
        },
      ];
    },
    linkClick(row) {
      this.detailPopup(row);
    },
    detailPopup(result) {
      this.popupOptions.target = () => import(`${"./baseinfo/dischargeFacDetail.vue"}`);
      this.popupOptions.title = '온실가스 배출시설 상세';
      this.popupOptions.param = {
        psiDocumentId: result,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
